import { apiSlice } from "../_api/api-slice";
import {
  Activity,
  ActivityDetails,
  ActivityGraph,
} from "../../types/rtk/activity/activity";

interface Agrs {
  trackerId: string;
  body?: {
    goal: number;
  };
  parameters?: string;
}

export const activity = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getActivity: build.query<Activity, Agrs>({
      query: ({ trackerId }) => ({
        url: `activity_tracking/${trackerId}/activity`,
        method: "get",
      }),
    }),
    getActivityDetails: build.query<ActivityDetails, Agrs>({
      query: ({ trackerId, parameters }) => ({
        url: `activity_tracking/${trackerId}/activity/details${
          parameters ? `?${parameters}` : ""
        }`,
        method: "get",
      }),
    }),
    getActivityGraph: build.query<ActivityGraph, Agrs>({
      query: ({ trackerId, parameters }) => ({
        url: `activity_tracking/${trackerId}/activity/graphs/available${
          parameters ? `?${parameters}` : ""
        }`,
        method: "get",
      }),
      transformResponse: (response: ActivityGraph): ActivityGraph => {
        return {
          ...response,
          total: response.total,
        };
      },
    }),
    updateActivity: build.mutation<any, Agrs>({
      query: ({ trackerId, body }) => ({
        url: `activity_tracking/${trackerId}/activity`,
        method: "put",
        body,
      }),
      invalidatesTags: ["dailyGoal"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetActivityQuery,
  useGetActivityDetailsQuery,
  useGetActivityGraphQuery,
  useUpdateActivityMutation,
} = activity;
