import { CSSProperties } from "react";
import { Box } from "@mui/material";
import { Doughnut } from "react-chartjs-2";

import { SummaryActivityTime } from "../../../types/rtk/summary/summary";

interface Props {
  id: string;
  datas: SummaryActivityTime;
  colors: string[];
  style?: CSSProperties;
  cutOut?: number;
}

const DoughnutActivityDistribution = ({
  datas,
  colors,
  style,
  cutOut = 100,
}: Props) => {
  return (
    <Box className="DoughnutActivityDistribution" sx={style}>
      <Doughnut
        data={{
          datasets: [
            {
              data: [datas.activity, datas.awakening, datas.rest],
              backgroundColor: colors,
              borderRadius: 0,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          cutout: cutOut,
        }}
      />
    </Box>
  );
};

export default DoughnutActivityDistribution;
