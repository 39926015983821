import { Box, Grid, LinearProgress } from "@mui/material";
import { ReactSVG } from "react-svg";

import Text from "../../atoms/Text/Text";

interface Props {
  name: string;
  value: number;
  logo: string;
  color: string;
}

const DisplayActivityDistribution = ({ name, value, logo, color }: Props) => {
  return (
    <Grid container spacing={3} className="DisplayActivityDistribution">
      <Grid item xs={3}>
        <Box className="logo">
          <ReactSVG src={logo} />
        </Box>
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={6}>
          <Text>{name}</Text>
        </Grid>
        <Grid item xs={6} className="alignText">
          <Text style={{ color: color }}>{`(${value}%)`}</Text>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{ "& .MuiLinearProgress-bar": { background: color } }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DisplayActivityDistribution;
