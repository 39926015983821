import { Grid } from "@mui/material";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  textArrow: string;
  link: string;
  subtitle?: string;
}

const SeeMore = ({ title, textArrow, link, subtitle }: Props) => {
  return (
    <Grid container spacing={2} className="SeeMore">
      <Grid item xs={9}>
        <Title variant="h3" style={{ margin: 0 }}>
          {title}
        </Title>
      </Grid>
      <Grid item xs={3} sx={{ fontSize: "16px" }}>
        <Link to={link} className="arrow">
          <Text>{textArrow}</Text>
          <ArrowForwardIosIcon />
        </Link>
      </Grid>
      {subtitle && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Text>{subtitle}</Text>
        </Grid>
      )}
    </Grid>
  );
};

export default SeeMore;
