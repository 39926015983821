import { Alert, Box } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Warning = ({ children }: Props) => {
  return (
    <Box className="Warning">
      <Alert severity="warning">{children}</Alert>
    </Box>
  );
};

export default Warning;
