import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import DistributionActivity from "../../atoms/DistributionActivity/DistributionActivity";
import Sun from "../../../assets/Sun.svg";
import Fire from "../../../assets/Fire.svg";
import Moon from "../../../assets/Moon.svg";
import { useGetSummaryActivityTimeQuery } from "../../../rtk/summary/summary";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { secondesToHours } from "../../../helpers/timeConverter";
import { SummaryActivityTime } from "../../../types/rtk/summary/summary";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const HomeActivityDistribution = () => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const [data, setData] = useState<SummaryActivityTime>({
    activity: 0,
    awakening: 0,
    rest: 0,
    grouping: "",
  });
  const { data: summaryData, isLoading } = useGetSummaryActivityTimeQuery(
    { trackerId: trackerId || "" },
    { skip: !trackerId }
  );

  useEffect(() => {
    if (summaryData) {
      setData(summaryData);
    }
  }, [summaryData]);

  if (!summaryData && isLoading) return <SpinnerLoader />;

  return (
    <StyledPaper
      noBorder
      className={`${!summaryData && isLoading && "loading"}`}
    >
      <Grid container spacing={1} className="HomeActivityDistribution">
        <Grid item xs={4} className="item">
          <DistributionActivity
            logo={Fire}
            title={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.INTENSE")}
            minutes={secondesToHours(data.activity ?? 0, t)}
            backgroundColor="#FDF0E8"
            color="#ED6E18"
          />
        </Grid>
        <Grid item xs={4} className="item">
          <DistributionActivity
            logo={Sun}
            title={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.MODERATE")}
            minutes={secondesToHours(data.awakening ?? 0, t)}
            backgroundColor="#FFF3D4"
            color="#F1B415"
          />
        </Grid>
        <Grid item xs={4} className="item">
          <DistributionActivity
            logo={Moon}
            title={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.WEAK")}
            minutes={secondesToHours(data.rest ?? 0, t)}
            backgroundColor="#EFE8FF"
            color="#6442AD"
          />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default HomeActivityDistribution;
