import { CSSProperties, ReactNode, useEffect } from "react";
import { Box } from "@mui/material";
import Chart from "chart.js/auto";

interface Props {
  id: string;
  current: number;
  goal: number;
  children?: ReactNode;
  isFullCircle?: boolean;
  style?: CSSProperties;
  cutOut?: number;
}

const Doughnut = ({
  id = "chartJSContainer",
  current,
  goal,
  children,
  isFullCircle = false,
  style,
  cutOut = 100,
}: Props) => {
  useEffect(() => {
    const ctx: any = document.getElementById(id);
    const options: any = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [
              current >= goal ? goal : current,
              goal - (current > goal ? goal : current),
            ],
            backgroundColor: isFullCircle
              ? current >= goal
                ? ["#87C215", "#F1F1F1"]
                : ["#A6A6A6", "#F1F1F1"]
              : ["#87C215", "#F1F1F1"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        rotation: 270,
        circumference: isFullCircle ? 360 : 180,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        cutout: cutOut,
        maintainAspectRatio: true,
        responsive: true,
      },
    };
    const myChart = new Chart(ctx, options);

    return () => {
      myChart.destroy();
    };
  }, [isFullCircle, id, cutOut, current, goal]);

  return (
    <Box className="Doughnut" sx={style}>
      <Box id={id} component={"canvas"} />
      <Box
        className="children"
        sx={{ marginTop: !isFullCircle ? "70px" : "0px" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Doughnut;
