import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./i18n/i18n";

import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import Main from "./components/organisms/Main/Main";
import Header from "./components/templates/Header/Header";
import AimedGoal from "./components/pages/AimedGoal/AimedGoal";
import ActivityDistribution from "./components/pages/ActivityDistribution/ActivityDistribution";
import ActivityReport from "./components/pages/ActivityReport/ActivityReport";
import { RootState } from "./types/rtk/api-config/types";

const App = () => {
  const theme = useSelector((state: RootState) => state.theme.mode);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      errorElement: <ErrorPage />,
    },
    {
      element: <Header />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "activity-report",
          element: <ActivityReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "activity-distribution",
          element: <ActivityDistribution />,
          errorElement: <ErrorPage />,
        },
        {
          path: "aimed-goal",
          element: <AimedGoal />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <Box className={`theme--${theme} `}>
      <Box className={`App`}>
        <RouterProvider router={router} />
      </Box>
    </Box>
  );
};

export default App;
