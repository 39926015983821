import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Doughnut from "../../atoms/Doughnut/Doughnut";
import trophyPath from "../../../assets/Trophy.svg";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import {
  secondesToHours,
  secondesToMinutes,
} from "../../../helpers/timeConverter";
import { getDayOfTheWeek } from "../../../helpers/getDayOfTheWeek";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { useGetSummaryWeekActivityQuery } from "../../../rtk/summary/summary";
import { SummaryWeek } from "../../../types/rtk/summary/summary";
import { nullableConverterNumber } from "../../../helpers/nullableConverter";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const HomeDailyGoal = () => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const [data, setData] = useState<SummaryWeek>({
    today: {
      date: "",
      goal: 0,
      current: 0,
    },
    weekly: [
      {
        date: "",
        goal: 0,
        current: 0,
      },
    ],
  });
  const { data: summaryData, isLoading } = useGetSummaryWeekActivityQuery(
    { trackerId: trackerId || "" },
    { skip: !trackerId }
  );

  useEffect(() => {
    if (summaryData) {
      setData(summaryData);
    }
  }, [summaryData]);

  if (!summaryData && isLoading) return <SpinnerLoader />;

  return (
    <StyledPaper
      noBorder
      className={`${!summaryData && isLoading && "loading"}`}
    >
      <Box className="HomeDailyGoal">
        <Doughnut
          id="chartJSContainer"
          style={{ marginTop: !summaryData && isLoading ? "0xp" : "-35px" }}
          current={secondesToMinutes(
            nullableConverterNumber(data.today.current)
          )}
          goal={secondesToMinutes(nullableConverterNumber(data.today.goal))}
        >
          <ReactSVG src={trophyPath} />
          <Title variant="h3" style={{ margin: 0 }}>
            {`${secondesToHours(nullableConverterNumber(data.today.current), t)}
              ${t("activityTracking:ACTIVITY")}`}
          </Title>
          <Text style={{ fontSize: "1px" }}>{`${secondesToHours(
            nullableConverterNumber(data.today.current),
            t
          )} / ${secondesToHours(
            nullableConverterNumber(data.today.goal),
            t
          )}`}</Text>
        </Doughnut>

        <Box
          className="weekDays"
          sx={{
            marginTop: !summaryData && isLoading ? "0px" : "-30px",
          }}
        >
          {data.weekly.map((day, i) => {
            return (
              <Doughnut
                id={`chartJSContainer${i}`}
                isFullCircle
                style={{ height: "40px" }}
                cutOut={13}
                current={secondesToMinutes(
                  nullableConverterNumber(day.current)
                )}
                goal={secondesToMinutes(nullableConverterNumber(day.goal))}
                key={i}
              >
                <Text style={{ marginTop: "12px" }}>
                  {getDayOfTheWeek(day.date, t, false)[0]}
                </Text>
              </Doughnut>
            );
          })}
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default HomeDailyGoal;
