import { useState, useCallback } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import Warning from "../../atoms/Warning/Warning";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import ProfileBanner from "../../molecules/ProfileBanner/ProfileBanner";
import DailyGoal from "../../molecules/DailyGoal/DailyGoal";
import { setUser } from "../../../rtk/user/user";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { useUpdateActivityMutation } from "../../../rtk/activity/activity";

const SecondStepOnBoarding = () => {
  const [goal, setGoal] = useState<number>(240);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const tracker = useAppSelector((state: RootState) => state.tracker);
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const [updateActivity] = useUpdateActivityMutation();

  const handlerInput = useCallback(async () => {
    try {
      const response = await updateActivity({
        trackerId: trackerId ?? "",
        body: { goal: goal },
      }).unwrap();
      dispatch(setUser({ ...user, isOnBoardingDone: true }));
      console.log("Response :", response);
    } catch (error) {
      console.log("Error :", error);
    }
  }, [goal, trackerId, updateActivity, dispatch, user]);

  return (
    <Grid container spacing={2} className="secondStepOnboarding">
      <Grid item xs={12}>
        <ProfileBanner
          title={t("activityTracking:ONBOARDING.SECONDE_STEP.PROFILE.TITLE", {
            petName: tracker.petName,
          })}
          subTitle={t(
            "activityTracking:ONBOARDING.SECONDE_STEP.PROFILE.SUBTITLE"
          )}
          src={tracker.petPictureUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <Title variant="h3">
          {t("activityTracking:ONBOARDING.SECONDE_STEP.TITLE")}
        </Title>
      </Grid>
      <Grid item xs={12}>
        <Warning>{t("activityTracking:AIMED_GOAL.ALERT")}</Warning>
      </Grid>
      <Grid item xs={12}>
        <DailyGoal
          title={t("activityTracking:DAILY_GOAL.TITLE")}
          goal={goal}
          setGoal={setGoal}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledButton
          text={t("activityTracking:ONBOARDING.SECONDE_STEP.BUTTON")}
          style={{ marginTop: "100px" }}
          onClick={handlerInput}
        />
      </Grid>
    </Grid>
  );
};

export default SecondStepOnBoarding;
