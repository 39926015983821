import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";
import dogsPath from "../../../assets/Dogs.svg";
import { ReactSVG } from "react-svg";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Box className="ErrorPage">
      <Title variant="h1">{t("activityTracking:ERROR_PAGE.TITLE")}</Title>
      <Text component="p" className="textErrorPage">
        {t("activityTracking:ERROR_PAGE.TEXT")}
      </Text>
      <ReactSVG src={dogsPath} />
      <Link to={AppRoutes.BASE_ROUTE}>
        <StyledButton>
          {t("activityTracking:ERROR_PAGE.BUTTON_TEXT")}
        </StyledButton>
      </Link>
    </Box>
  );
};

export default ErrorPage;
