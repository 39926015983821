import { Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ProfilePicture from "../../atoms/ProfilePicture/ProfilePicture";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import Warning from "../../atoms/Warning/Warning";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";

interface Props {
  setPassToSecondeStep: Dispatch<SetStateAction<boolean>>;
}

const FirstStepOnBoarding = ({ setPassToSecondeStep }: Props) => {
  const { t } = useTranslation();
  const petPictureUrl = useAppSelector((state: RootState) => state.tracker.petPictureUrl);

  return (
    <Grid container spacing={2} className="firstStepOnboarding">
      <Grid item xs={12}>
        <ProfilePicture src={petPictureUrl} />
      </Grid>
      <Grid item xs={12} style={{ padding: "30px 1px 0px 16px" }}>
        <Title variant="h1" style={{ marginBottom: "0px" }}>
          {t("activityTracking:ONBOARDING.FIRST_STEP.TITLE")}
        </Title>
      </Grid>
      <Grid item xs={12}>
        <Text component="p">
          {t("activityTracking:ONBOARDING.FIRST_STEP.MESSAGE")}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Warning>{t("activityTracking:ONBOARDING.FIRST_STEP.ALERT")}</Warning>
      </Grid>
      <Grid item xs={12}>
        <StyledButton
          text={t("activityTracking:ONBOARDING.FIRST_STEP.BUTTON")}
          style={{ marginTop: "100px" }}
          onClick={() => setPassToSecondeStep(true)}
        />
      </Grid>
    </Grid>
  );
};

export default FirstStepOnBoarding;
