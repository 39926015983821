import { apiSlice } from "../_api/api-slice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyTracker, TrackerState } from "../../types/rtk/tracker/tracker";


interface Agrs {
  trackerId: string;
  body?: {
    goal: number;
  };
  parameters?: string;
}

export const tracker = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMyTracker: build.query<MyTracker, Agrs>({
      query: ({ trackerId }) => ({
        url: `mytracker/${trackerId}`,
        method: "get",
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetMyTrackerQuery,
} = tracker;

const initialState: TrackerState = {
  petName: "",
  timezone: "",
  petPictureUrl: "",
};

const TrackerSlice = createSlice({
  name: "tracker",
  initialState: initialState,
  reducers: {
    setTracker: (state, action: PayloadAction<TrackerState>) => {
      state.petName = action.payload.petName;
      state.timezone = action.payload.timezone;
      state.petPictureUrl = action.payload.petPictureUrl;
    },
  },
});

export const { setTracker } = TrackerSlice.actions;
const TrackerReducer = TrackerSlice.reducer;
export default TrackerReducer;