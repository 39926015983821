import { useEffect, useState } from "react";
import {
  // Button,
  // ButtonGroup,
  // Divider,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import DataComparator from "../../molecules/DataComparator/DataComparator";
import FrenquencyChoice from "../../molecules/FrenquencyChoice/FrenquencyChoice";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import { getDayOfTheWeek } from "../../../helpers/getDayOfTheWeek";
import { get2DaysAgo } from "../../../helpers/timeConverter";
import { getComparator } from "../../../helpers/getComparator";
import { enumGrouping } from "../../../types/enums/enumGrouping";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import { useGetActivityGraphQuery } from "../../../rtk/activity/activity";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { enumMetrics } from "../../../types/enums/enumMetrics";
import { useGetSummaryKeysQuery } from "../../../rtk/summary/summary";
import BarChart from "../../atoms/BarChart/BarChart";
import { enumUnit } from "../../../types/enums/enumUnit";
import { nullableConverterNumber } from "../../../helpers/nullableConverter";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const ActivityReport = () => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const petName = useAppSelector((state: RootState) => state.tracker.petName);
  const tz = useAppSelector((state: RootState) => state.tracker.timezone);
  //TODO go back with state when km is available
  const metric = enumMetrics.PAS;
  // const [metric, setMetric] = useState<enumMetrics>(enumMetrics.PAS);
  const [groupingKeys, setGroupingKeys] = useState<enumGrouping>(
    enumGrouping.DAY
  );
  const [groupingKeysGraph, setGroupingKeysGraph] = useState<enumGrouping>(
    enumGrouping.DAY
  );
  const [data, setData] = useState<{
    firstDay: number | null;
    lastDay: number | null;
    firstWeek: number | null;
    lastWeek: number | null;
    firstMonth: number | null;
    lastMonth: number | null;
  }>({
    firstDay: null,
    lastDay: null,
    firstWeek: null,
    lastWeek: null,
    firstMonth: null,
    lastMonth: null,
  });
  const [dataGraph, setDataGraph] = useState<{
    [T in enumGrouping.DAY | enumGrouping.WEEK | enumGrouping.MONTH]: {
      activity: number;
      date: string;
    }[];
  }>({
    [enumGrouping.DAY]: [{ activity: 0, date: "" }],
    [enumGrouping.WEEK]: [{ activity: 0, date: "" }],
    [enumGrouping.MONTH]: [{ activity: 0, date: "" }],
  });
  const [totalGraph, setTotalGraph] = useState<{
    [T in enumGrouping.DAY | enumGrouping.WEEK | enumGrouping.MONTH]: number;
  }>({
    [enumGrouping.DAY]: 0,
    [enumGrouping.WEEK]: 0,
    [enumGrouping.MONTH]: 0,
  });
  const [frequency, setFrequency] = useState<enumGrouping>(enumGrouping.DAY);
  const [fetchingDone, setFetchingDone] = useState<boolean>(false);
  const [fetchingDoneGraph, setFetchingDoneGraph] = useState<boolean>(false);
  const { data: activityGraph, isLoading: isLoadingGraph } =
    useGetActivityGraphQuery(
      {
        trackerId: trackerId || "",
        parameters: getUrlParameters({
          grouping: groupingKeysGraph,
          metric,
          tz,
        }),
      },
      { skip: !trackerId }
    );
  const { data: summary, isLoading: isLoadingSummary } = useGetSummaryKeysQuery(
    {
      trackerId: trackerId || "",
      parameters: getUrlParameters({ grouping: groupingKeys, metric, tz }),
    },
    { skip: !trackerId }
  );

  useEffect(() => {
    if (activityGraph && !fetchingDoneGraph) {
      if (
        activityGraph.grouping === enumGrouping.DAY &&
        dataGraph[enumGrouping.DAY] !== activityGraph.activity
      ) {
        setDataGraph({
          ...dataGraph,
          [enumGrouping.DAY]: activityGraph.activity,
        });
        setTotalGraph({
          ...totalGraph,
          [enumGrouping.DAY]: activityGraph.total,
        });
        setGroupingKeysGraph(enumGrouping.WEEK);
      }
      if (
        activityGraph.grouping === enumGrouping.WEEK &&
        dataGraph[enumGrouping.WEEK] !== activityGraph.activity
      ) {
        setDataGraph({
          ...dataGraph,
          [enumGrouping.WEEK]: activityGraph.activity,
        });
        setTotalGraph({
          ...totalGraph,
          [enumGrouping.WEEK]: activityGraph.total,
        });
        setGroupingKeysGraph(enumGrouping.MONTH);
      }
      if (
        activityGraph.grouping === enumGrouping.MONTH &&
        dataGraph[enumGrouping.MONTH] !== activityGraph.activity
      ) {
        setDataGraph({
          ...dataGraph,
          [enumGrouping.MONTH]: activityGraph.activity,
        });
        setTotalGraph({
          ...totalGraph,
          [enumGrouping.MONTH]: activityGraph.total,
        });
        setGroupingKeysGraph(enumGrouping.DAY);
        setFetchingDoneGraph(true);
      }
    }
  }, [activityGraph, dataGraph, fetchingDoneGraph, totalGraph]);

  useEffect(() => {
    if (summary && !fetchingDone) {
      if (
        summary.grouping === enumGrouping.DAY &&
        data.firstDay !== summary.first.value
      ) {
        setData({
          ...data,
          firstDay: nullableConverterNumber(summary.first.value),
          lastDay: nullableConverterNumber(summary.last.value),
        });
        setGroupingKeys(enumGrouping.WEEK);
      }
      if (
        summary.grouping === enumGrouping.WEEK &&
        data.firstWeek !== summary.first.value
      ) {
        setData({
          ...data,
          firstWeek: nullableConverterNumber(summary.first.value),
          lastWeek: nullableConverterNumber(summary.last.value),
        });
        setGroupingKeys(enumGrouping.MONTH);
      }
      if (
        summary.grouping === enumGrouping.MONTH &&
        data.firstMonth !== summary.first.value
      ) {
        setData({
          ...data,
          firstMonth: nullableConverterNumber(summary.first.value),
          lastMonth: nullableConverterNumber(summary.last.value),
        });
        setGroupingKeys(enumGrouping.DAY);
        setFetchingDone(true);
      }
    }
  }, [summary, data, fetchingDone]);

  if (!activityGraph && isLoadingGraph) return <SpinnerLoader />;

  return (
    <Grid container spacing={2} className="ActivityReport">
      {/* <Grid item xs={12} className="noPaddingTop">
        <ButtonGroup
          variant="text"
          aria-label="text button group"
          className="buttonGroupe"
        >
          <Button
            className={metric === enumMetrics.PAS ? "active" : ""}
            onClick={() => {
              setFetchingDone(false);
              setMetric(enumMetrics.PAS);
            }}
            disabled
          >
            {t("activityTracking:STEP")}
          </Button>
            // TODO: Uncomment once kilometers metrcis are available
            <Button
            className={metric === enumMetrics.KM ? "active" : ""}
            onClick={() => {
              setFetchingDone(false);
              setMetric(enumMetrics.KM);
            }}
          >
            {t("activityTracking:KILOMETERS")}
          </Button>
        </ButtonGroup>
      </Grid> */}
      {/* <Grid item xs={12} className="noPaddingTop">
        <Divider />
      </Grid> */}

      <Grid item xs={12}>
        <Text className="black-text bold-text">
          {t("activityTracking:TOTAL").toUpperCase()}
        </Text>
      </Grid>
      <Grid item xs={12} className="noPaddingTop">
        <Text className="black-text">
          {t(
            metric === enumMetrics.PAS
              ? "activityTracking:STEP_SAVED"
              : "activityTracking:ACTIVITY_REPORT.KM_TRAVELED",
            {
              step: totalGraph[frequency as keyof typeof totalGraph],
              // metric === enumMetrics.PAS
              //   ? activityGraph?.total
              //   : parseInt(activityGraph?.total.replace(" ", "") ?? "") ?? 0,
            }
          )}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BarChart
          frequency={frequency}
          datas={dataGraph[frequency as keyof typeof dataGraph].map((obj) => {
            return {
              y: obj.activity,
              x:
                frequency === enumGrouping.DAY
                  ? new Date(obj.date).getHours() + "h"
                  : frequency === enumGrouping.WEEK
                  ? getDayOfTheWeek(obj.date, t, false)
                  : `${new Date(obj.date).getDate()}/${
                      new Date(obj.date).getMonth() + 1
                    }`,
            };
          })}
          metric={metric}
        />
      </Grid>
      <Grid item xs={12}>
        <FrenquencyChoice frequency={frequency} setFrequency={setFrequency} />
      </Grid>
      <Grid item xs={12}>
        <Title variant="h3">{t("activityTracking:KEY_POINTS")}</Title>
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t("activityTracking:DAILY_STEP")}
          text={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_STEP_DAILY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_KM_DAILY",
            {
              petName,
              comparator: getComparator(
                nullableConverterNumber(data.lastDay),
                nullableConverterNumber(data.firstDay),
                t
              ),
            }
          )}
          valueOne={nullableConverterNumber(data.lastDay)}
          legendOne={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_YESTERDAY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_YESTERDAY"
          )}
          valueTwo={nullableConverterNumber(data.firstDay)}
          legendTwo={`${t(
            metric === enumMetrics.PAS
              ? "activityTracking:STEP"
              : "activityTracking:KILOMETERS"
          ).toLowerCase()} ${getDayOfTheWeek(get2DaysAgo(), t, true)}`}
          unit={metric === enumMetrics.PAS ? enumUnit.STEP : enumUnit.DISTANCE}
          isLoading={!summary && isLoadingSummary}
        />
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t("activityTracking:WEEKLY_STEP")}
          text={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_STEP_WEEKLY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_KM_WEEKLY",
            {
              petName,
              comparator: getComparator(
                nullableConverterNumber(data.lastWeek),
                nullableConverterNumber(data.firstWeek),
                t
              ),
            }
          )}
          valueOne={nullableConverterNumber(data.lastWeek)}
          legendOne={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_YESTERDAY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_YESTERDAY"
          )}
          valueTwo={nullableConverterNumber(data.firstWeek)}
          legendTwo={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_WEEKLY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_WEEKLY"
          )}
          unit={metric === enumMetrics.PAS ? enumUnit.STEP : enumUnit.DISTANCE}
          isLoading={!summary && isLoadingSummary}
          key={metric}
        />
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t("activityTracking:MONTHLY_STEP")}
          text={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_STEP_MONTHLY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_KM_MONTHLY",
            {
              petName,
              comparator: getComparator(
                nullableConverterNumber(data.lastMonth),
                nullableConverterNumber(data.firstMonth),
                t
              ),
            }
          )}
          valueOne={nullableConverterNumber(data.lastMonth)}
          legendOne={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_WEEKLY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_WEEKLY"
          )}
          valueTwo={nullableConverterNumber(data.firstMonth)}
          legendTwo={t(
            metric === enumMetrics.PAS
              ? "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_MONTHLY"
              : "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_MONTHLY"
          )}
          unit={metric === enumMetrics.PAS ? enumUnit.STEP : enumUnit.DISTANCE}
          isLoading={!summary && isLoadingSummary}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityReport;
