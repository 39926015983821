import { Box, Button } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
  text?: string;
  children?: ReactNode;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const StyledButton = ({ text, children, style, onClick }: Props) => {
  return (
    <Box className="StyledButton" sx={style}>
      <Button variant="contained" onClick={onClick}>
        {children || text}
      </Button>
    </Box>
  );
};

export default StyledButton;
