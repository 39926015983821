import { CSSProperties } from "react";
import { Box } from "@mui/material";
import "chartjs-adapter-moment";
import { Bar } from "react-chartjs-2";

import { enumGrouping } from "../../../types/enums/enumGrouping";
import { enumMetrics } from "../../../types/enums/enumMetrics";

interface Props {
  datas: { y: number; x: string }[];
  frequency: enumGrouping;
  metric: enumMetrics;
  style?: CSSProperties;
}

const BarChart = ({ datas, style, metric, frequency }: Props) => {
  return (
    <Box className="BarChart" sx={style}>
      <Bar
        data={{
          labels: datas.map((o) => o.x),
          datasets: [
            {
              data: datas,
              backgroundColor: "#ed6e18",
              borderRadius: 100,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                stepSize: 5,
                maxRotation: 0,
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                stepSize:
                  metric === enumMetrics.PAS
                    ? frequency === enumGrouping.DAY
                      ? 500
                      : 5000
                    : 2,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default BarChart;
