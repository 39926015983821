import { Box } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Container = ({ children }: Props) => {
  return <Box className="App-container">{children}</Box>;
};

export default Container;
