import { Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import FrenquencyButton from "../../atoms/FrenquencyButton/FrenquencyButton";
import { enumGrouping } from "../../../types/enums/enumGrouping";

interface Props {
  frequency: string;
  setFrequency: Dispatch<SetStateAction<enumGrouping>>;
}

const FrenquencyChoice = ({ frequency, setFrequency }: Props) => {
  const { t } = useTranslation();
  const frequencyChoices = [
    t("activityTracking:PERIOD.DAY"),
    t("activityTracking:PERIOD.WEEK"),
    t("activityTracking:PERIOD.MONTH"),
  ];

  return (
    <Grid item container spacing={2} className="FrenquencyChoice">
      {frequencyChoices.map((obj, i) => (
        <Grid item xs={4} key={i}>
          <FrenquencyButton
            frequency={frequency}
            setFrequency={setFrequency}
            text={obj}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FrenquencyChoice;
