import { Box, Grid } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import Container from "../Container/Container";
import { AppRoutes } from "../../../routes/AppRoutes";

const Header = () => {
  window.scrollTo(0, 0);
  let location = useLocation();
  const { t } = useTranslation();

  const titleHandler = (url: string): string => {
    let title: string = "";
    switch (url) {
      case AppRoutes.GOAL:
        title = t("activityTracking:HEADER.GOAL.TITLE");
        break;
      case AppRoutes.REPORT:
        title = t("activityTracking:HEADER.ACTIVITY_REPORT.TITLE");
        break;
      case AppRoutes.DISTRIBUTION:
        title = t("activityTracking:HEADER.ACTIVITY_DISTRIBUTION.TITLE");
        break;
    }

    return title;
  };

  return (
    <Box className="HeaderTemplate">
      <Box className="header">
        <Grid container spacing={2}>
          <Grid item xs={3} className="arrow">
            <Link to={AppRoutes.BASE_ROUTE}>
              <ArrowBackIosIcon className="arrowColor" />
            </Link>
          </Grid>
          <Grid item xs={6} className="title">
            <Title variant="h3" style={{ marginBottom: 0 }}>
              {titleHandler(location.pathname)}
            </Title>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
      <Container>
        <Outlet />
      </Container>
    </Box>
  );
};

export default Header;
