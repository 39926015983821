import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import OnBoarding from "../../pages/OnBoarding/OnBoarding";
import { setTheme } from "../../../rtk/theme/theme";
import { RootState } from "../../../types/rtk/api-config/types";
import { setUser, useGetMyUserQuery } from "../../../rtk/user/user";
import Home from "../../pages/Home/Home";
import { useGetTokenMutation } from "../../../rtk/auth/login";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setAuth } from "../../../rtk/auth/auth";
import Container from "../../templates/Container/Container";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useGetActivityQuery } from "../../../rtk/activity/activity";
import { setTracker, useGetMyTrackerQuery } from "../../../rtk/tracker/tracker";
import { getTimezoneOffset } from "../../../helpers/timezoneConverter";
import { getDiffBetweenDateAndToday } from "../../../helpers/getDiffBetweenDateAndToday";

const Main = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  const dark = searchParams.get("dark");
  const lang = searchParams.get("lang");
  const mailTokenParams = searchParams.get("mail_token");
  const mailTokenLocalStore = useAppSelector(
    (state: RootState) => state.auth.mail_token
  );
  const mailToken = mailTokenParams ? mailTokenParams : mailTokenLocalStore;
  const user = useAppSelector((state: RootState) => state.user);
  const tracker = useAppSelector((state: RootState) => state.tracker);
  const trackerIdParams = searchParams.get("trackerId");
  const trackerIdLocalStore = useAppSelector(
    (state: RootState) => state.auth.trackerId
  );
  const trackerId = trackerIdParams ? trackerIdParams : trackerIdLocalStore;
  const isOnBoardingDone = useAppSelector(
    (state: RootState) => state.user.isOnBoardingDone
  );
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [getToken] = useGetTokenMutation();
  const dispatch = useAppDispatch();

  const { data: myUser, isLoading: isLoadingMyUser } = useGetMyUserQuery("", {
    skip: !token,
  });

  const { data: activityData, isLoading: isActivityDataLoading } =
    useGetActivityQuery(
      { trackerId: trackerId || "" },
      { skip: !trackerId || !token }
    );

  const { data: trackerData, isLoading: isTrackerDataLoading } =
    useGetMyTrackerQuery(
      { trackerId: trackerId || "" },
      { skip: !trackerId || !token }
    );

  const isLoading =
    isActivityDataLoading || isTrackerDataLoading || isLoadingMyUser;

  useEffect(() => {
    if (mailTokenParams && trackerIdParams) {
      dispatch(
        setAuth({
          token: token,
          mail_token: mailTokenParams,
          trackerId: trackerIdParams,
        })
      );
    }
  }, [mailTokenParams, trackerIdParams, token, dispatch]);

  useEffect(() => {
    if (mailToken && trackerId) {
      const fetchToken = async () => {
        try {
          const response = await getToken({ mail_token: mailToken }).unwrap();
          dispatch(
            setAuth({
              token: response.access_token,
              mail_token: mailToken,
              trackerId: trackerId,
            })
          );
          console.log("Response :", response);
        } catch (error) {
          console.log("Error :", error);
        }
      };

      if (token === undefined) {
        fetchToken();
      }
    }
  }, [getToken, mailToken, trackerId, dispatch, token]);

  useEffect(() => {
    if (activityData && myUser) {
      dispatch(
        setUser({
          ...user,
          isOnBoardingDone: isOnBoardingDone
            ? isOnBoardingDone
            : Boolean(
                activityData.time.goal &&
                  (activityData.time.goal !== null ||
                    activityData.time.goal !== 0)
              ),
        })
      );
    }
  }, [activityData, dispatch, isOnBoardingDone, myUser, user]);

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      dispatch(
        setUser({
          ...user,
          lang: lang,
        })
      );
    } else if (
      !lang &&
      !user.lang &&
      myUser &&
      myUser.language !== i18n.language
    ) {
      dispatch(
        setUser({
          ...user,
          lang: myUser.language,
        })
      );
    }
  }, [i18n, dispatch, user, lang, myUser]);

  useEffect(() => {
    if (trackerData) {
      dispatch(
        setTracker({
          ...tracker,
          petName: trackerData.name ?? "",
          petPictureUrl:
            trackerData.url_picture ??
            "https://static.weenect.com/weenect-squareLogo-1631273894342.png",
          timezone: trackerData.timezone
            ? getTimezoneOffset(trackerData.timezone)
            : "0",
        })
      );
    }
  }, [trackerData, dispatch, tracker]);

  useEffect(() => {
    if (dark) {
      dispatch(setTheme({ mode: dark === "0" ? "light" : "dark" }));
    }
  }, [dark, dispatch]);

  useEffect(() => {
    if (user.lang && user.lang !== i18n.language) {
      i18n.changeLanguage(user.lang);
    }
  }, [user, i18n]);

  return (
    <Container>
      {activityData && trackerData && myUser && !isLoading ? (
        isOnBoardingDone ? (
          <Home
            displayWarning={
              getDiffBetweenDateAndToday(trackerData.activation_date) < 31
            }
            country={myUser?.country}
          />
        ) : (
          <OnBoarding />
        )
      ) : (
        <SpinnerLoader />
      )}
    </Container>
  );
};

export default Main;
