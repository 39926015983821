import { Grid } from "@mui/material";

import ProfilePicture from "../../atoms/ProfilePicture/ProfilePicture";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
  src?: string;
}

const ProfileBanner = ({ title, subTitle, isLoading, src }: Props) => {
  if (isLoading) return <SpinnerLoader />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ProfilePicture src={src} />
      </Grid>
      <Grid item xs={9} style={{ paddingRight: "16px", paddingTop: "16px" }}>
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Title variant="h3">{title}</Title>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Text>{subTitle}</Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileBanner;
