import { TFunction } from "i18next";

export const secondesToMinutes = (secondes: number): number =>
  Math.round(secondes / 60);

export const secondesToHours = (
  secondes: number,
  t: TFunction<"translation", undefined>
): string =>
  Math.floor(secondes / 3600) > 0
    ? `${t("activityTracking:HOUR", { count: Math.floor(secondes / 3600) })} ${
        Math.floor((secondes % 3600) / 60) > 0
          ? t("activityTracking:MIN", {
              count: Math.floor((secondes % 3600) / 60),
            })
          : ""
      }`
    : t("activityTracking:MIN", {
        count: Math.floor((secondes % 3600) / 60),
      });

export const get2DaysAgo = (): string => {
  var b = new Date();
  b.setDate(b.getDate() - 2);
  return b.toString();
};
