import { apiSlice } from "../_api/api-slice";
import {
  SummaryActivityTime,
  SummaryKeys,
  SummaryWeek,
} from "../../types/rtk/summary/summary";

interface Agrs {
  trackerId: string;
  body?: {
    goal: number;
  };
  parameters?: string;
}

export const summary = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSummaryWeekActivity: build.query<SummaryWeek, Agrs>({
      query: ({ trackerId }) => ({
        url: `activity_tracking/${trackerId}/summary/week`,
        method: "get",
      }),
      providesTags: ["dailyGoal"],
    }),
    getSummaryActivityTime: build.query<SummaryActivityTime, Agrs>({
      query: ({ trackerId, parameters }) => ({
        url: `activity_tracking/${trackerId}/summary/activity/time${
          parameters ? `?${parameters}` : ""
        }`,
        method: "get",
      }),
    }),
    getSummaryKeys: build.query<SummaryKeys, Agrs>({
      query: ({ trackerId, parameters }) => ({
        url: `activity_tracking/${trackerId}/summary/keys${
          parameters ? `?${parameters}` : ""
        }`,
        method: "get",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSummaryWeekActivityQuery,
  useGetSummaryActivityTimeQuery,
  useGetSummaryKeysQuery,
} = summary;
