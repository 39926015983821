import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import DataComparator from "../../molecules/DataComparator/DataComparator";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import DisplayActivityDistribution from "../../molecules/DisplayActivityDistribution/DisplayActivityDistribution";
import firePath from "../../../assets/Fire.svg";
import sunPath from "../../../assets/Sun.svg";
import moonPath from "../../../assets/Moon.svg";
import FrenquencyChoice from "../../molecules/FrenquencyChoice/FrenquencyChoice";
import { getDayOfTheWeek } from "../../../helpers/getDayOfTheWeek";
import { get2DaysAgo } from "../../../helpers/timeConverter";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { useGetSummaryActivityTimeQuery } from "../../../rtk/summary/summary";
import { getPercentage } from "../../../helpers/getPercentage";
import { enumGrouping } from "../../../types/enums/enumGrouping";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import {
  useGetActivityDetailsQuery,
  useGetActivityGraphQuery,
} from "../../../rtk/activity/activity";
import DoughnutActivityDistributionTest from "../../atoms/DoughnutActivityDistribution/DoughnutActivityDistribution";
import { enumUnit } from "../../../types/enums/enumUnit";
import { enumMetrics } from "../../../types/enums/enumMetrics";
import { SummaryActivityTime } from "../../../types/rtk/summary/summary";
import { ActivityDetails } from "../../../types/rtk/activity/activity";
import { nullableConverterNumber } from "../../../helpers/nullableConverter";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

const ActivityDistribution = () => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const petName = useAppSelector((state: RootState) => state.tracker.petName);
  const tz = useAppSelector((state: RootState) => state.tracker.timezone);
  const [frequency, setFrequency] = useState<enumGrouping>(enumGrouping.DAY);
  const [urlParams, setUrlParams] = useState<string>(
    getUrlParameters({ grouping: frequency, tz: tz })
  );
  const [dataActivity, setDataActivity] = useState<SummaryActivityTime>({
    activity: 0,
    awakening: 0,
    rest: 0,
    grouping: "",
  });
  const [dataActivityDetails, setDataActivityDetails] =
    useState<ActivityDetails>({
      unit: "",
      grouping: enumGrouping.DAY,
      activity: {
        first: {
          start: "",
          end: "",
          value: 0,
        },
        last: {
          start: "",
          end: "",
          value: 0,
        },
      },
      awakening: {
        first: {
          start: "",
          end: "",
          value: 0,
        },
        last: {
          start: "",
          end: "",
          value: 0,
        },
      },
      rest: {
        first: {
          start: "",
          end: "",
          value: 0,
        },
        last: {
          start: "",
          end: "",
          value: 0,
        },
      },
    });
  const { data: activity, isLoading: isLoadingActivity } =
    useGetSummaryActivityTimeQuery(
      { trackerId: trackerId || "", parameters: urlParams },
      { skip: !trackerId }
    );
  const { data: activityDetails, isLoading: isLoadingActivityDetails } =
    useGetActivityDetailsQuery(
      { trackerId: trackerId || "" },
      { skip: !trackerId }
    );
  const { data: activityGraph, isLoading: isLoadingGraph } =
    useGetActivityGraphQuery(
      {
        trackerId: trackerId || "",
        parameters: getUrlParameters({
          grouping: frequency,
          metric: enumMetrics.PAS,
          tz: tz,
        }),
      },
      { skip: !trackerId }
    );

  useEffect(() => {
    setUrlParams(getUrlParameters({ grouping: frequency, tz: tz }));
    //TODO
  }, [frequency, tz]);

  useEffect(() => {
    if (activity) {
      setDataActivity(activity);
    }
  }, [activity]);

  useEffect(() => {
    if (activityDetails) {
      setDataActivityDetails(activityDetails);
    }
  }, [activityDetails]);

  const getComparator = (
    valueOne: number,
    valueTwo: number,
    t: (text: string) => string
  ): string => {
    if (valueOne > valueTwo) {
      if (valueOne - valueTwo < 300) {
        return t("activityTracking:EQUAL");
      }
      return t("activityTracking:PLUS");
    } else {
      if (valueTwo - valueOne < 300) {
        return t("activityTracking:EQUAL");
      }
      return t("activityTracking:LESS");
    }
  };

  if (!activityGraph && isLoadingGraph && !activity && isLoadingActivity)
    return <SpinnerLoader />;

  return (
    <Grid container spacing={2} className="ActivityDistribution">
      <Grid item xs={12}>
        <Text className="black-text bold-text">
          {t("activityTracking:TOTAL").toUpperCase()}
        </Text>
      </Grid>
      <Grid item xs={12} className="noPaddingTop">
        <Text className="black-text">
          {activityGraph &&
            t("activityTracking:STEP_SAVED", { step: activityGraph.total })}
        </Text>
      </Grid>
      <Grid item xs={6}>
        <DisplayActivityDistribution
          name={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.INTENSE")}
          value={getPercentage({
            value: dataActivity.activity,
            total:
              dataActivity.activity +
              dataActivity.awakening +
              dataActivity.rest,
          })}
          color={"#ED6E18"}
          logo={firePath}
        />
        <DisplayActivityDistribution
          name={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.MODERATE")}
          value={getPercentage({
            value: dataActivity.awakening,
            total:
              dataActivity.activity +
              dataActivity.awakening +
              dataActivity.rest,
          })}
          color={"#F9C744"}
          logo={sunPath}
        />
        <DisplayActivityDistribution
          name={t("activityTracking:HOME.ACTIVITY_DISTRIBUTION.WEAK")}
          value={getPercentage({
            value: dataActivity.rest,
            total:
              dataActivity.activity +
              dataActivity.awakening +
              dataActivity.rest,
          })}
          color={"#7759B8"}
          logo={moonPath}
        />
      </Grid>
      <Grid item xs={6} className="alignCenter">
        <DoughnutActivityDistributionTest
          id={`chartJSContainerActivityDistribution`}
          style={{ height: "125px" }}
          cutOut={45}
          datas={dataActivity}
          colors={["#ED6E18", "#F9C744", "#7759B8"]}
        />
      </Grid>
      <Grid item xs={12}>
        <FrenquencyChoice frequency={frequency} setFrequency={setFrequency} />
      </Grid>
      <Grid item xs={12}>
        <Title variant="h3">{t("activityTracking:KEY_POINTS")}</Title>
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_INTENSE"
          )}
          text={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.TEXT",
            {
              petName,
              activity: t(
                "activityTracking:HOME.ACTIVITY_DISTRIBUTION.INTENSE"
              ).toLocaleLowerCase(),
              comparator: getComparator(
                nullableConverterNumber(
                  dataActivityDetails.activity.last.value
                ),
                nullableConverterNumber(
                  dataActivityDetails.activity.first.value
                ),
                t
              ).toLocaleLowerCase(),
              day: getDayOfTheWeek(get2DaysAgo(), t, true),
            }
          )}
          valueOne={nullableConverterNumber(
            dataActivityDetails.activity.last.value
          )}
          legendOne={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_INTENSE_LEGEND",
            { day: t("activityTracking:YESTERDAY") }
          )}
          valueTwo={nullableConverterNumber(
            dataActivityDetails.activity.first.value
          )}
          legendTwo={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_INTENSE_LEGEND",
            { day: getDayOfTheWeek(get2DaysAgo(), t, true) }
          )}
          unit={enumUnit.TIME}
          isLoading={!activityDetails && isLoadingActivityDetails}
        />
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_MODERATE"
          )}
          text={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.TEXT",
            {
              petName,
              activity: t(
                "activityTracking:HOME.ACTIVITY_DISTRIBUTION.MODERATE"
              ).toLocaleLowerCase(),
              comparator: getComparator(
                nullableConverterNumber(
                  dataActivityDetails.awakening.last.value
                ),
                nullableConverterNumber(
                  dataActivityDetails.awakening.first.value
                ),
                t
              ).toLocaleLowerCase(),
              day: getDayOfTheWeek(get2DaysAgo(), t, true),
            }
          )}
          valueOne={nullableConverterNumber(
            dataActivityDetails.awakening.last.value
          )}
          legendOne={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_MODERATE_LEGEND",
            { day: t("activityTracking:YESTERDAY") }
          )}
          valueTwo={nullableConverterNumber(
            dataActivityDetails.awakening.first.value
          )}
          legendTwo={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_MODERATE_LEGEND",
            { day: getDayOfTheWeek(get2DaysAgo(), t, true) }
          )}
          unit={enumUnit.TIME}
          isLoading={!activityDetails && isLoadingActivityDetails}
        />
      </Grid>
      <Grid item xs={12}>
        <DataComparator
          title={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_WEAK"
          )}
          text={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.TEXT",
            {
              petName,
              activity: t(
                "activityTracking:HOME.ACTIVITY_DISTRIBUTION.WEAK"
              ).toLocaleLowerCase(),
              comparator: getComparator(
                nullableConverterNumber(dataActivityDetails.rest.last.value),
                nullableConverterNumber(dataActivityDetails.rest.first.value),
                t
              ).toLocaleLowerCase(),
              day: getDayOfTheWeek(get2DaysAgo(), t, true),
            }
          )}
          valueOne={nullableConverterNumber(
            dataActivityDetails.rest.last.value
          )}
          legendOne={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_WEAK_LEGEND",
            { day: t("activityTracking:YESTERDAY") }
          )}
          valueTwo={nullableConverterNumber(
            dataActivityDetails.rest.first.value
          )}
          legendTwo={t(
            "activityTracking:ACTIVITY_DISTRIBUTION.DATA_COMPARATOR.ACTIVITY_WEAK_LEGEND",
            { day: getDayOfTheWeek(get2DaysAgo(), t, true) }
          )}
          unit={enumUnit.TIME}
          isLoading={!activityDetails && isLoadingActivityDetails}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityDistribution;
