import { Box } from "@mui/material";
import Text from "../Text/Text";
import Title from "../Title/Title";

interface Props {
  logo: string;
  title: string;
  minutes: string;
  color: string;
  backgroundColor: string;
}

const DistributionActivity = ({
  logo,
  title,
  minutes,
  color,
  backgroundColor,
}: Props) => {
  return (
    <Box
      className="DistributionActivity"
      sx={{ backgroundColor: backgroundColor, color: color }}
    >
      <Box className="logo">
        <img src={logo} alt="logo" />
      </Box>
      <Box className="text">
        <Text>{title}</Text>
        <Title variant="h3" style={{ margin: 0, whiteSpace: "break-spaces" }}>
          {minutes}
        </Title>
      </Box>
    </Box>
  );
};

export default DistributionActivity;
