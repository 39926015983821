import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserState } from "../../types/rtk/user/user";
import { apiSlice } from "../_api/api-slice";

export const tracker = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMyUser: build.query<User, string>({
      query: () => ({
        url: `myuser`,
        method: "get",
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetMyUserQuery } = tracker;

const initialState: UserState = {
  isOnBoardingDone: false,
  lang: "",
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.isOnBoardingDone = action.payload.isOnBoardingDone;
      state.lang = action.payload.lang;
    },
  },
});

export const { setUser } = UserSlice.actions;
const UserReducer = UserSlice.reducer;
export default UserReducer;
