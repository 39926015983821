import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";

import { apiSlice } from "../_api/api-slice";
import ThemeReducer from "../theme/theme";
import UserReducer from "../user/user";
import AuthReducer from "../auth/auth";
import TrackerReducer from "../tracker/tracker";
import { summary } from "./../summary/summary";
import { activity } from "../activity/activity";

const combineSlices = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  activity: activity.reducer,
  summary: summary.reducer,
  theme: ThemeReducer,
  user: UserReducer,
  tracker: TrackerReducer,
  auth: AuthReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api"],
};

const persistedReducer = persistReducer(persistConfig, combineSlices);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

export const persistedStore = persistStore(store);
export default store;
