import { Login, PostLogin } from "../../types/rtk/auth/login";
import { apiSlice } from "../_api/api-slice";

const login = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getToken: build.mutation<Login, PostLogin>({
      query: (data) => ({
        url: `auth/login/mail`,
        method: "post",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTokenMutation } = login;
