import { useState } from "react";
import { Box } from "@mui/material";

import FirstStepOnBoarding from "../../organisms/FirstStepOnBoarding/FirstStepOnBoarding";
import SecondStepOnBoarding from "../../organisms/SecondeStepOnBoarding/SecondeStepOnBoarding";

const OnBoarding = () => {
  const [passToSecondeStep, setPassToSecondeStep] = useState(false);

  return (
    <Box className="OnBoarding">
      {!passToSecondeStep ? (
        <FirstStepOnBoarding setPassToSecondeStep={setPassToSecondeStep} />
      ) : (
        <SecondStepOnBoarding />
      )}
    </Box>
  );
};

export default OnBoarding;
