export const getTimezoneOffset = (tz: string): string => {
  const d = new Date();
  const dateArrStr = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
  let monthNum = parseInt(dateArrStr[1], 10)
  monthNum--;
  dateArrStr[1] = monthNum.toString();
  const dateArrNumber = dateArrStr.map(str => parseInt(str, 10));
  const t1 = Date.UTC.apply(null, [dateArrNumber[0], dateArrNumber[1], dateArrNumber[2], dateArrNumber[3], dateArrNumber[4], dateArrNumber[5]]);
  const t2 = new Date(d).setMilliseconds(0);
  const offset = (t2 - t1) / 60 / 1000 / 60;
  return offset.toString()
}