import { Box, Avatar } from "@mui/material";

interface Props {
  size?: number;
  src?: string;
}

const ProfilePicture = ({
  size = 70,
  src = "https://static.weenect.com/weenect-squareLogo-1631273894342.png",
}: Props) => {
  return (
    <Box className="ProfilePicture">
      <Avatar sx={{ width: size, height: size }} src={src} />
    </Box>
  );
};

export default ProfilePicture;
