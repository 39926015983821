import {
  createApi,
  fetchBaseQuery,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../types/rtk/api-config/types";
import { config as appConfig } from "../../config";
import { setAuth } from "../auth/auth";

const query = fetchBaseQuery({
  baseUrl: appConfig.REACT_APP_APIV4_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("authorization", `JWT ${token}`);
    }

    return headers;
  },
});

const baseQuery = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  let result = await query(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    const refreshResult = await query(
      {
        url: `auth/login/mail`,
        method: "POST",
        body: {
          mail_token: (api.getState() as RootState).auth.mail_token,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      // @ts-ignore
      const token: string = refreshResult.data.access_token;
      // store the new token
      api.dispatch(
        setAuth({
          token: token,
          mail_token: (api.getState() as RootState).auth.mail_token,
          trackerId: (api.getState() as RootState).auth.trackerId,
        })
      );
      // retry the initial query

      result = await query(args, api, extraOptions);
    } else {
      api.dispatch(
        setAuth({
          token: undefined,
          mail_token: (api.getState() as RootState).auth.mail_token,
          trackerId: (api.getState() as RootState).auth.trackerId,
        })
      );
      // window.location.href = "/error";
    }
  } else if (result.error) {
    // window.location.href = "/error";
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: ["dailyGoal"],
});
