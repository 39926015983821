import { Grid, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import { secondesToHours } from "../../../helpers/timeConverter";
import { getPercentage } from "../../../helpers/getPercentage";
import { enumUnit } from "../../../types/enums/enumUnit";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  title: string;
  text: string;
  valueOne: number;
  legendOne: string;
  valueTwo: number;
  legendTwo: string;
  unit?: enumUnit;
  isLoading?: boolean;
}

const DataComparator = ({
  title,
  text,
  valueOne,
  legendOne,
  valueTwo,
  legendTwo,
  unit,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  if (isLoading) return <SpinnerLoader />;

  return (
    <StyledPaper noBorder={isLoading} className={`${isLoading && "loading"}`}>
      <Grid container spacing={2} className="DataComparator">
        <Grid item xs={12} className="title">
          <Title variant="h3" style={{ marginBottom: 0 }}>
            {title}
          </Title>
        </Grid>
        <Grid item xs={12} className={!isLoading ? "noPaddingTop" : ""}>
          <Text>{text}</Text>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "baseline" }}>
          <Title
            variant="h3"
            style={{
              marginBottom: 0,
              marginRight: "8px",
              textTransform: "lowercase",
              whiteSpace: "break-spaces",
            }}
          >
            {unit === enumUnit.TIME ? secondesToHours(valueOne, t) : valueOne}
          </Title>
          {!isLoading && <Text className="toLowerCase">{legendOne}</Text>}
        </Grid>
        <Grid item xs={12} className={!isLoading ? "noPaddingTop" : ""}>
          <LinearProgress
            variant="determinate"
            value={
              valueOne > valueTwo
                ? 100
                : getPercentage({ value: valueOne, total: valueTwo })
            }
            className={valueOne > valueTwo ? "darkBar" : "lightBar"}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "baseline" }}>
          <Title
            variant="h3"
            style={{
              marginBottom: 0,
              marginRight: "8px",
              textTransform: "lowercase",
              whiteSpace: "break-spaces",
            }}
          >
            {unit === enumUnit.TIME ? secondesToHours(valueTwo, t) : valueTwo}
          </Title>
          {!isLoading && <Text className="toLowerCase">{legendTwo}</Text>}
        </Grid>
        <Grid item xs={12} className={!isLoading ? "noPaddingTop" : ""}>
          <LinearProgress
            variant="determinate"
            value={
              valueTwo > valueOne
                ? 100
                : getPercentage({ value: valueTwo, total: valueOne })
            }
            className={valueTwo > valueOne ? "darkBar" : "lightBar"}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default DataComparator;
