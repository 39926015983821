import { UrlParameters } from "../types/urlParameters/urlParameters";

export const getUrlParameters = (parameters: UrlParameters): string => {
  let urlParameters = "";
  if (parameters.tz) {
    urlParameters = `&tz=${parameters.tz}`;
  }
  if (parameters.grouping) {
    urlParameters += `&grouping=${parameters.grouping}`;
  }
  if (parameters.unit) {
    urlParameters += `&unit=${parameters.unit}`;
  }
  if (parameters.metric) {
    urlParameters += `&metric=${parameters.metric}`;
  }
  return urlParameters;
};
