export const getDayOfTheWeek = (
  date: string,
  t: (text: string) => string,
  comparative: boolean
): string => {
  let dayIndex = new Date(date).getDay();

  switch (dayIndex) {
    case 0:
      return comparative
        ? t("activityTracking:WEEKDAYS.SUNDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.SUNDAY");
    case 1:
      return comparative
        ? t("activityTracking:WEEKDAYS.MONDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.MONDAY");
    case 2:
      return comparative
        ? t("activityTracking:WEEKDAYS.TUESDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.TUESDAY");
    case 3:
      return comparative
        ? t("activityTracking:WEEKDAYS.WEDNESDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.WEDNESDAY");
    case 4:
      return comparative
        ? t("activityTracking:WEEKDAYS.THURSDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.THURSDAY");
    case 5:
      return comparative
        ? t("activityTracking:WEEKDAYS.FRIDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.FRIDAY");
    case 6:
      return comparative
        ? t("activityTracking:WEEKDAYS.SATURDAY_COMPARATIVE")
        : t("activityTracking:WEEKDAYS.SATURDAY");
    default:
      return "";
  }
};
