export const getComparator = (
  valueOne: number,
  valueTwo: number,
  t: (text: string) => string
): string => {
  if (valueOne > valueTwo) {
    if (valueOne - valueTwo < 50) {
      return t("activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_SIM");
    }
    return t("activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_SUP");
  } else {
    if (valueTwo - valueOne < 50) {
      return t("activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_SIM");
    }
    return t("activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_INF");
  }
};
