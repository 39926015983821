import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import { enumGrouping } from "../../../types/enums/enumGrouping";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  frequency: string;
  setFrequency: Dispatch<SetStateAction<enumGrouping>>;
  className?: string;
}

const FrenquencyButton = ({
  text,
  className,
  frequency,
  setFrequency,
}: Props) => {
  const { t } = useTranslation();

  const getFrequency = (frenquency: string): enumGrouping => {
    switch (frenquency) {
      case t("activityTracking:PERIOD.DAY"):
        return enumGrouping.DAY;
      case t("activityTracking:PERIOD.WEEK"):
        return enumGrouping.WEEK;
      case t("activityTracking:PERIOD.MONTH"):
        return enumGrouping.MONTH;
      default:
        return enumGrouping.DAY;
    }
  };

  return (
    <Button
      variant="outlined"
      className={`FrenquencyButton ${
        frequency === getFrequency(text) && "active"
      }`}
      fullWidth
      onClick={() => setFrequency(getFrequency(text))}
      // disabled
    >
      {text}
    </Button>
  );
};

export default FrenquencyButton;
